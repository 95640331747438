import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Roles from '../../components/Roles/Roles';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Präsidium`}</h1>
      <h2 {...{
        "id": "das-fvdh-prasidium-setzt-sich-wie-folgt-zusammen"
      }}>{`Das FVDH-Präsidium setzt sich wie folgt zusammen:`}</h2>
      <h3 {...{
        "id": "vizeprasident"
      }}>{`Vizepräsident`}</h3>
      <Roles role_name='Vizepräsident' mdxType="Roles" />
      <h3 {...{
        "id": "prasidiumsmitglieder"
      }}>{`Präsidiumsmitglieder`}</h3>
      <Roles role_name='Präsidium' mdxType="Roles" />
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      